<!-- 学习成长记录 -->
<template>
    <div class="my_stidy_log">
        <div class="warpper">
            <h2 class="title">学习成长记录</h2>
        </div>
        <div class="logContent">
            <div class="myTabs">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="培训记录" name="study">
                        <!-- 筛选条件 -->
                        <div class="searchContent flex-align">
                            <div class="items flex-align">
                                <div class="options">时间:</div>
                                <el-date-picker v-model="value1" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" size='mini' @change="changeTime"
                                    value-format='yyyy-MM-dd HH:mm:ss'>
                                </el-date-picker>
                            </div>
                            <div class="items flex-align">
                                <div class="options">状态:</div>
                                <el-select v-model="statusVal" placeholder="请选择" size='mini' @change="changeStatus">
                                    <el-option v-for="item in status" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="items flex-align">
                                <div class="options">考核结果:</div>
                                <el-select v-model="resultVal" placeholder="请选择" size='mini' disabled>
                                    <el-option v-for="item in result" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <!-- 筛选结果 table -->
                        <el-table :data="stuData" style="width: 100%">
                            <el-table-column prop="name" label="项目名称" width="180" align="center">
                            </el-table-column>
                            <el-table-column label="项目时间" width="250" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.periodStartTime.split(' ')[0]}}-{{scope.row.periodFinishTime.split(' ')[0]}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="status" label="状态" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.state ==0?'未开始':scope.row.state ==1?'进行中':'已完成'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="考核结果" align="center">--</el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="同步学习记录" name="sync">
                        <div class="searchContent flex-align">
                            <div class="items flex-align">
                                <div class="options">时间:</div>
                                <el-date-picker v-model="value1" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" size='mini' @change="changeTime"
                                    value-format='yyyy-MM-dd HH:mm:ss'>
                                </el-date-picker>
                            </div>
                            <div class="items flex-align">
                                <div class="options">状态:</div>
                                <el-select v-model="statusVal" placeholder="请选择" size='mini' @change="changeStatus">
                                    <el-option v-for="item in status" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="items flex-align">
                                <div class="options">学习进度:</div>
                                <el-select v-model="studyVal" placeholder="请选择" size='mini' disabled>
                                    <el-option v-for="item in studyStatus" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <el-table :data="syncData" style="width: 100%">
                            <el-table-column prop="courseName" label="课程名称" align="center" width="180">
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目名称" align="center" width="180">
                            </el-table-column>
                            <el-table-column prop="time" label="课程时间" align="center" width="250">
                                <template slot-scope="scope">
                                    {{timestampToDate(scope.row.beginTime*1000).split(' ')[0]}}-{{timestampToDate(scope.row.endTime*1000).split(' ')[0]}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="status" align="center" label="课程状态">
                                <template slot-scope="scope">
                                    {{scope.row.state ==0?'未开始':scope.row.state ==1?'进行中':'已完成'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="studyStatus" align="center" label="学习进度">
                                <template slot-scope="scope">
                                    {{scope.row.studyState?scope.row.studyState:'--'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="score" align="center" label="学分">
                                <template slot-scope="scope">
                                    {{scope.row.score?scope.row.score:'--'}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="异步课程" name="async">
                        <div class="searchContent flex-align">
                            <div class="items flex-align">
                                <div class="options">时间:</div>
                                <el-date-picker v-model="value1" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" size='mini' @change="changeTime"
                                    value-format='yyyy-MM-dd HH:mm:ss'>
                                </el-date-picker>
                            </div>
                            <div class="items flex-align">
                                <div class="options">状态:</div>
                                <el-select v-model="statusVal" placeholder="请选择" size='mini' @change="changeStatus">
                                    <el-option v-for="item in status" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="items flex-align">
                                <div class="options">学习进度:</div>
                                <el-select v-model="studyVal" placeholder="请选择" size='mini' disabled>
                                    <el-option v-for="item in studyStatus" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <!-- <div class="items flex-align">
                                <div class="options">课程类型:</div>
                                <el-select v-model="statusVal" placeholder="请选择" size='mini'>
                                    <el-option v-for="item in status" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div> -->
                        </div>
                        <el-table :data="asyncData" style="width: 100%">
                            <el-table-column prop="name" label="项目名称" align="center" width="180">
                            </el-table-column>
                            <el-table-column align="center" label="学习时长/个数">
                                <template slot-scope="scope">
                                    {{scope.row.score?scope.row.score:'--'}}
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="是否达标">
                                <template slot-scope="scope">
                                    {{scope.row.ruleFinish?scope.row.ruleFinish:'--'}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="活动记录" name="activity">
                        <div class="searchContent flex-align">
                            <div class="items flex-align">
                                <div class="options">时间:</div>
                                <el-date-picker v-model="value1" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" size='mini' @change="changeTime"
                                    value-format='yyyy-MM-dd HH:mm:ss'>
                                </el-date-picker>
                            </div>
                            <div class="items flex-align">
                                <div class="options">状态:</div>
                                <el-select v-model="statusVal" placeholder="请选择" size='mini' @change="changeStatus">
                                    <el-option v-for="item in status" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                        <el-table :data="activitycData" style="width: 100%">
                            <el-table-column prop="projectName" label="活动名称" align="center" width="180">
                            </el-table-column>
                            <el-table-column prop="projectName" label="项目名称" align="center" width="180">
                            </el-table-column>
                            <el-table-column prop="count" align="status" label="状态">
                            </el-table-column>
                            <el-table-column prop="count" align="center" label="活动结果数量">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="浏览记录" name="borwse">
                        <div class="searchContent flex-align">
                            <div class="items flex-align">
                                <div class="options">时间:</div>
                                <el-date-picker v-model="value1" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" size='mini' @change="changeTime"
                                    value-format='yyyy-MM-dd HH:mm:ss'>
                                </el-date-picker>
                            </div>
                        </div>
                        <el-table :data="browseData" style="width: 100%">
                            <el-table-column prop="filename" label="浏览内容" align="center">
                            </el-table-column>
                            <el-table-column prop="updateTime" label="操作时间" align="center">
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="学分排行记录" name="score">
                        <div class="searchContent flex-align">
                            <div class="items flex-align">
                                <div class="options">时间:</div>
                                <el-date-picker v-model="value1" type="datetimerange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" size='mini' @change="changeTime"
                                    value-format='yyyy-MM-dd HH:mm:ss'>
                                </el-date-picker>
                            </div>
                        </div>
                        <el-table :data="rankData" style="width: 100%">
                            <el-table-column prop="projectName" label="项目名称" align="center">
                            </el-table-column>
                            <el-table-column label="时间" align="center" width="280">
                                <template slot-scope="scope">
                                    {{scope.row.startTime.split(' ')[0]}}-{{scope.row.finishTime.split(' ')[0]}}
                                </template>
                            </el-table-column>
                            <el-table-column label="积分总数" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.total?scope.row.total:'--'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="ranking" label="排名" align="center">
                                <template slot-scope="scope">
                                    {{scope.row.ranking?scope.row.ranking:'--'}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="个人能力报告" name="report" v-if="phone==13812341234 || phone == 15801590890">
                        <!-- <el-tab-pane label="个人能力报告" name="report"> -->
                        <div class="personal-report flex-align">
                            <div class="ability-personal-main">
                                <div class="charts ">
                                    <div class="composite-charts">
                                        <h3 class="charts-title">综合个人能力</h3>
                                        <Sunburst :suitId="Number(23)"></Sunburst>
                                    </div>
                                    <div class="personal-charts">
                                        <h3 class="charts-title">个人能力图谱</h3>
                                        <Radar :suitId="Number(23)"></Radar>
                                    </div>
                                </div>
                                <div class="ability-table" v-for="(ability, index) in abilityList" :key="index">
                                    <div class="ability-table-title-part flex-align-between">
                                        <div class="form-title">{{ ability.title }}（获得{{ ability.score }}分）</div>
                                        <button class="promote-btn csp" @click="isShowDialog(index)">
                                            <i class="rocket"></i>
                                            提升能力
                                        </button>
                                    </div>
                                    <div class="alibity-table-main">
                                        <el-table :data="ability.children" border style="width: 100%">
                                            <el-table-column prop="secendAbilityName" align="center" label="二级能力">
                                            </el-table-column>
                                            <el-table-column prop="workName" align="center" label="作业名称">
                                            </el-table-column>
                                            <el-table-column prop="value" align="center" label="能力分">
                                            </el-table-column>
                                        </el-table>
                                    </div>
                                </div>
                            </div>
                            <el-dialog title="提示" :visible.sync="dialogVisible" width="450px" top="30vh" :center="true">
                                <div class="content">
                                    您目前在“{{ dialogTitle }}”上获得
                                    <span style="color:#508EF9">{{ dialogScore }}能力分</span> ，
                                    快去提升吧！
                                </div>
                                <span slot="footer" class="dialog-footer">
                                    <el-button type="primary" @click="promote">去提升</el-button>
                                </span>
                            </el-dialog>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <div class="mySearch" v-if="activeName != 'report'">
                <el-input class="searchInput"
                    :placeholder="activeName=='study'?'按项目名称':activeName=='sync'?'按课程名称或教师姓名':activeName=='async'?'按课程名称或教师姓名':''"
                    size='mini' suffix-icon="el-icon-search" v-model="searchVal" @input="changeInput">
                </el-input>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import Sunburst from "./component/pa-sunburst.vue";
    import Radar from "./component/alibity-radar.vue";
    import abilityJson from "./component/ability.json";
    export default {
        components: {
            Sunburst,
            Radar,
        },
        data() {
            return {
                abilityList: [],
                activeName: 'study',
                status: [{
                    value: 0,
                    label: '未开始'
                }, {
                    value: 1,
                    label: '进行中'
                }, {
                    value: 2,
                    label: '已完成'
                }],
                studyStatus: [{
                    value: 0,
                    label: '无'
                }, {
                    value: 1,
                    label: '已完成'
                }, {
                    value: 2,
                    label: '未完成'
                }],
                result: [{
                    value: 0,
                    label: '优秀'
                }, {
                    value: 1,
                    label: '良好'
                }, {
                    value: 2,
                    label: '合格'
                }, {
                    value: 3,
                    label: '不合格'
                }],
                courseType: [{

                }],
                value1: '',
                stuData: [],
                syncData: [],
                asyncData: [],
                activitycData: [],
                browseData: [],
                rankData: [],
                searchVal: '',
                currentPage: 1,
                limit: 8,
                total: 0,
                startTime: '',
                endTime: '',
                statusVal: '',
                resultVal: '',
                studyVal: '',
                dialogVisible: false,
                dialogTitle: "",
                dialogScore: "",
            }
        },
        computed: {
            ...mapState({
                phone: state => state.user.userInfo.phone,
            })
        },
        methods: {
            handleClick() {
                // 清空筛选条件
                this.searchVal = '';
                this.statusVal = '';
                this.studyVal = '';
                this.resultVal = '';
                this.value1 = [];
                this.getLogByType()
            },
            handleSizeChange(page) {
                this.page = page;
                this.getLogByType()
            },
            handleCurrentChange(page) {
                this.page = page;
                this.getLogByType()
            },
            changeTime(val) {
                console.log(val)
                this.startTime = val[0];
                this.endTime = val[1];
                this.getLogByType()
            },
            changeStatus(val) {
                console.log(val)
                this.getLogByType();
            },
            changeInput() {
                this.getLogByType()
            },
            // 格式化时间
            timestampToDate(timestamp) {
                // 使用时间戳创建Date对象
                var date = new Date(timestamp);
                // 获取年、月、日、小时、分钟和秒
                var year = date.getFullYear();
                var month = ("0" + (date.getMonth() + 1)).slice(-2);
                var day = ("0" + date.getDate()).slice(-2);
                var hours = ("0" + date.getHours()).slice(-2);
                var minutes = ("0" + date.getMinutes()).slice(-2);
                var seconds = ("0" + date.getSeconds()).slice(-2);
                // 拼接日期字符串
                var formattedDate = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
                return formattedDate;
            },
            // 获取不同类型的记录
            getLogByType() {
                if (this.activeName == 'study') {
                    console.log('获取培训记录');
                    this.getStuLog()
                } else if (this.activeName == 'sync') {
                    console.log('获取同步记录');
                    this.getSyncLog()
                } else if (this.activeName == 'async') {
                    console.log('获取异步记录');
                    this.getAsyncLog()
                } else if (this.activeName == 'activity') {
                    console.log('获取活动记录');
                    this.getActivityLog()
                } else if (this.activeName == 'borwse') {
                    console.log('获取浏览记录');
                    this.getBorwseLog()
                } else {
                    console.log('获取学分记录');
                    this.getRankData()
                }
            },
            // 培训记录
            getStuLog() {
                let data = {
                    periodStartTime: this.startTime,
                    periodFinishTime: this.endTime,
                    page: this.currentPage,
                    limit: this.limit,
                    state: this.statusVal,
                    name: this.searchVal
                };
                this.$Api.Score.stuLog(data)
                    .then(res => {
                        // console.log(res);
                        this.stuData = res.data.records;
                        this.total = res.data.total
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 同步课学习记录
            getSyncLog() {
                let data = {
                    startTime: this.startTime,
                    finishTime: this.endTime,
                    page: this.currentPage,
                    limit: this.limit,
                    state: this.statusVal,
                    kesys: this.searchVal,
                    state: this.statusVal,
                    studyState: this.studyVal,
                    lessonsPattern: 0,
                    projectId: this.$store.state.user.currentProject.id
                };
                this.$Api.Score.syncLog(data)
                    .then(res => {
                        console.log('res:', res);
                        this.syncData = res.data.records;
                        this.total = res.data.total
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 异步课学习记录
            getAsyncLog() {
                let data = {
                    startTime: this.startTime,
                    finishTime: this.endTime,
                    page: this.currentPage,
                    limit: this.limit,
                    state: this.statusVal,
                    kesys: this.searchVal,
                    state: this.statusVal,
                    studyState: this.studyVal,
                    lessonsPattern: 1,
                    projectId: this.$store.state.user.currentProject.id
                };
                this.$Api.Score.asyncLog(data)
                    .then(res => {
                        console.log('res:', res);
                        this.asyncData = res.data.records;
                        this.total = res.data.total
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 活动记录
            getActivityLog() {
                let data = {
                    startTime: this.startTime,
                    finishTime: this.endTime,
                    page: this.currentPage,
                    limit: this.limit,
                    state: this.statusVal,
                    kesys: this.searchVal,
                    state: this.statusVal,
                    studyState: this.studyVal,
                    projectId: this.$store.state.user.currentProject.id
                };
                this.$Api.Score.activityLog(data)
                    .then(res => {
                        console.log('res:', res);
                        this.activitycData = res.data.records;
                        this.total = res.data.total
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 浏览记录
            getBorwseLog() {
                let data = {
                    startTime: this.startTime,
                    finishTime: this.endTime,
                    page: this.currentPage,
                    limit: this.limit,
                }
                this.$Api.Score.browseLog(data)
                    .then(res => {
                        console.log('浏览记录:', res);
                        this.browseData = res.data.records;
                        this.total = res.data.total
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 学分排行记录
            getRankData() {
                let data = {
                    startTime: this.startTime,
                    finishTime: this.endTime,
                    page: this.currentPage,
                    limit: this.limit,
                }
                this.$Api.Score.rankLog(data)
                    .then(res => {
                        console.log('学分排行记录:', res);
                        this.rankData = res.data.records;
                        this.total = res.data.total
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
            // 个人能力报告
            getAbilityData() {
                let json = abilityJson;
                if (Object.prototype.isPrototypeOf(json)) {
                    Object.keys(json).forEach(key => {
                        let sum = 0;
                        if (Array.prototype.isPrototypeOf(json[key]) && json[key].length > 0) {
                            json[key].forEach((val, index) => {
                                sum += val.value;
                            })
                        }
                        let value = {
                            title: key,
                            abilityId: json[key][0].firstAbility,
                            score: sum,
                            children: json[key]
                        }
                        this.abilityList.push(value)
                    })
                }
            },
            // 个人能力报告弹框提示
            isShowDialog(index) {
                this.dialogVisible = true;
                this.dialogTitle = this.abilityList[index].title;
                this.dialogScore = this.abilityList[index].score;
                this.abilityId = this.abilityList[index].abilityId;
            },
            promote() {
                this.dialogVisible = false;
                this.$router.push({
                    name: "myImprove",
                    query: {
                        title: this.dialogTitle,
                        // abilityId: this.abilityId,
                        suitId: 23,
                        breadNumber: 5,
                    },
                });
            },

        },
        mounted() {
            this.getLogByType();
            this.getAbilityData();
        },
    };
</script>
<style lang='less' scoped>
    .my_stidy_log {
        border-radius: 10px;
        background: #ffffff;
        position: relative;

        .warpper {
            padding-left: 9px;
        }

        .title {
            border-bottom: 1px solid #f9f9f9;
            padding-left: 35px;
            line-height: 69px;

            font-size: 22px;
        }

        .logContent {
            padding: 10px;
            position: relative;

            .mySearch {
                position: absolute;
                top: 60px;
                right: 30px;
            }

            .myTabs {
                .searchContent {
                    margin-bottom: 20px;

                    .items {
                        .options {
                            flex-shrink: 0;
                            margin-right: 10px;
                            margin-left: 10px;
                        }
                    }
                }

                .myPages {
                    margin-top: 30px;
                }
            }

            /deep/ .el-tabs__item {
                font-size: 13px;
            }

            /deep/ .el-tabs__nav-scroll::after {
                background-color: #fff;
            }

            /deep/ .el-tabs__header .el-tabs__nav-wrap::after {
                background-color: #fff;
            }

            /deep/ .el-tabs__content {
                padding: 0 20px;
            }

            // /deep/ .el-input__inner {
            //     width: 100px;
            // }

            /deep/ .el-range-editor--mini.el-input__inner {
                width: 200px;
            }

            /deep/ .el-tabs__header {
                margin-bottom: 60px !important;
            }
        }

        .personal-report {
            .ability-personal-main {
                padding: 50px 36px;

                .charts {
                    padding: 0 40px;

                    .charts-title {
                        font-size: 15px;
                        font-weight: 600;

                        &::before {
                            display: inline-block;
                            content: ' ';
                            margin-right: 8px;
                            width: 3px;
                            height: 15px;
                            border-radius: 3px;
                            background-color: #508EF9;
                            vertical-align: -2px;
                        }
                    }

                    #myChart-Sunburst,
                    #myChart-Radar {
                        width: 350px;
                        height: 350px;
                    }

                    #myChart-Sunburst {
                        margin-left: 0px;
                    }

                    #myChart-Radar {
                        margin-right: 0px;
                    }
                }

                .ability-table {
                    margin-top: 31px;

                    .ability-table-title-part {
                        border-bottom: 1px solid #F2F2F2;
                        margin-bottom: 20px;
                        padding: 0 19px 13px 40px;
                    }

                    .form-title {
                        font-size: 15px;
                        font-weight: 600;

                        &::before {
                            display: inline-block;
                            content: ' ';
                            margin-right: 8px;
                            width: 3px;
                            height: 15px;
                            border-radius: 3px;
                            background-color: #508EF9;
                            vertical-align: -2px;
                        }
                    }

                    .promote-btn {
                        padding: 0 15px;
                        line-height: 28px;
                        border-radius: 4px;
                        color: #fff;
                        font-size: 13px;
                        background-color: #508EF9;
                        box-shadow: 0px 4px 11px 0px rgba(22, 115, 255, 0.28);

                        &:hover {
                            background-color: rgba(80, 142, 249, 0.8);
                        }

                        .rocket {
                            display: inline-block;
                            margin-right: 7px;
                            width: 15px;
                            height: 15px;
                            background-image: url("../../assets/images/ability/rocket.png");
                            background-size: cover;
                            vertical-align: -3px;
                        }
                    }

                    .alibity-table-main {
                        padding: 0 20px;

                        /deep/.el-table {
                            border-radius: 5px;
                            overflow: hidden;

                            th {
                                background: #FAFBFF;
                                color: #333;
                            }
                        }
                    }
                }

            }

            /deep/ .el-dialog {
                border-radius: 5px;
                overflow: hidden;

                .el-dialog__header {
                    background-image: url("../../assets/images/teach/create-title.png");
                    background-size: cover;
                    line-height: 40px;
                    padding: 0;

                    .el-dialog__title {
                        font-size: 17px;
                        color: #fff;
                    }

                    .el-dialog__headerbtn {
                        top: 10px;
                    }

                    .el-dialog__close {
                        color: #fff
                    }
                }

                .el-dialog__body {
                    padding: 40px 40px 65px;
                }

                .el-button {
                    padding: 0 30px;
                    line-height: 30px;
                    font-size: 13px;
                    background-color: #508EF9;
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .my_modify_psd {
            max-width: 660px;
            width: 660px;
        }
    }
</style>