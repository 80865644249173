<template>
  <!-- personal ability sunburst  my 综合个人能力 旭日图 -->
  <div class="ability-sunburst">
    <div class="chart-box" v-show="sunburstFlag">
      <div id="sunburst"></div>
    </div>
    <!-- 无内容-->
    <Empty :show="!sunburstFlag"></Empty>
    <!-- <Empty :show="true"></Empty> -->
  </div>

</template>

<script>
  export default {
    props: {
      suitId: {
        type: Number
      },
    },
    data() {
      return {
        sunburstFlag: true,
        sunburstData: [],
        colorMap: [{
            color: '#5470c6',
            childColor: 'rgba(84,112,198,0.8)'
          },
          {
            color: '#73c0de',
            childColor: 'rgba(115,192,222,0.8)'
          },
          {
            color: '#fac858',
            childColor: 'rgba(250,200,88,0.8)'
          },
          {
            color: '#ee6666',
            childColor: 'rgba(238,102,102,0.8)'
          },
          {
            color: '#91cc75',
            childColor: 'rgba(145,204,117,0.8)'
          },
          {
            color: '#3ba272',
            childColor: 'rgba(59,162,114,0.8)'
          },
          {
            color: '#fc8452',
            childColor: 'rgba(252,132,82,0.8)'
          },
          {
            color: '#9a60b4',
            childColor: 'rgba(154,96,180,0.8)'
          },
          {
            color: '#ea7ccc',
            childColor: 'rgba(234,124,204,0.8)'
          }
        ],
      }
    },
    watch: {
      suitId: {
        handler(newVal, oldVal) {
          if (!newVal) return;
          this.getAbilitySunburst();
        },
        immediate: true,
      }
    },
    methods: {
      // 获取综合个人能力数据数据
      async getAbilitySunburst() {
        let params = {
          suitId: this.suitId
        }
        let res = await this.$Api.DST.userFristAbility(params);
        // let resData = res.data;
        let resData = [{
            "id": 24,
            "name": "教学设计能力",
            "childList": [{
                "id": 25,
                "name": "学情分析能力",
                "value": 33
              },
              {
                "id": 30,
                "name": "教学目标编制能力",
                "value": 18
              }
            ]
          },
          {
            "id": 50,
            "name": "课程资源开发、利用能力",
            "childList": [{
              "id": 51,
              "name": "课程理解能力",
              "value": 14
            }]
          },
          {
            "id": 58,
            "name": "教学示范能力",
            "childList": [{
                "id": 59,
                "name": "口语表达能力",
                "value": 18
              },
              {
                "id": 65,
                "name": "体态表达能力",
                "value": 33
              }
            ]
          },
          {
            "id": 79,
            "name": "教学交往能力",
            "childList": [{
              "id": 80,
              "name": "问答能力",
              "value": 5
            }]
          },
          {
            "id": 90,
            "name": "教学管理能力",
            "childList": [{
              "id": 94,
              "name": "课堂组织能力",
              "value": 8
            }]
          },
          {
            "id": 101,
            "name": "评价学生的能力",
            "childList": [{
              "id": 102,
              "name": "评价学生能力",
              "value": 10
            }]
          },
          {
            "id": 105,
            "name": "教学研究创新能力",
            "childList": [{
              "id": 106,
              "name": "学习研究能力",
              "value": 11
            }]
          }
        ];

        if (Array.prototype.isPrototypeOf(resData) && resData.length > 0) {
          this.sunburstFlag = true;
          resData.map((item, index) => {
            item.itemStyle = {
              color: this.colorMap[index].color
            }
            item.childList.map((subItem) => {
              subItem.name = `${subItem.name} ${subItem.value}`
              subItem.label = {
                textBorderColor: this.colorMap[index].color,
                textBorderWidth: 2
              }
              subItem.itemStyle = {
                color: this.colorMap[index].childColor,
                opacity: 1,
              }
            })
            item.children = item.childList;
          })
          // console.log(resData);
          this.sunburstData = resData;
          this.drawRadar();
        } else {
          this.sunburstFlag = false;
        }
      },
      drawRadar() {
        let myChartCustom = this.$echarts.init(
          document.getElementById(`sunburst`)
        );
        window.addEventListener('resize', () => {
          myChartCustom.resize()
        })
        myChartCustom.setOption({
          // color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4',
          //   '#ea7ccc'
          // ],
          // color: ['#da0d68','#da1d23','#ebb40f', '#187a2f','#0aa3b5','#c94930','#ad213e','#a87b64','#e65832'],
          series: [{
            type: "sunburst",
            center: ["50%", "50%"],
            radius: [0, '100%'],
            nodeClick: false,
            data: this.sunburstData,
            sort: function (a, b) {
              if (a.depth === 1) {
                return b.getValue() - a.getValue();
              } else {
                return a.dataIndex - b.dataIndex;
              }
            },
            label: {
              rotate: "radial",
              fontSize: 12,

              //     formatter: function (params) {
              //       var strlength = params.name.length;
              //       if (strlength % 4 == 2) {
              //         params.name = params.name.replace(/\S{4}/g, function (match) {
              //               // console.log(match);
              //               return match + '\n'
              //           })
              //       } else {
              //         params.name = params.name.replace(/\S{4}/g, function (match) {
              //               // console.log(match);
              //               return match + '\n'
              //           })
              //           strlength = params.name.length;
              //           params.name = params.name.substring(0, strlength);
              //       }
              //       return params.name
              //   },
            },
            itemStyle: {
              borderWidth: 1,
            },
            levels: [{},
              {
                // r0: '0%',
                // r: '45%',
                label: {
                  show: true,
                  rotate: 'radial',
                  align: "right",
                  color: '#fff',
                  // minAngle: 25,
                  // width: 60,
                  // overflow: 'truncate',
                }
              },
              {
                // r0: '45%',
                // r: '100%',
                label: {
                  show: true,
                  rotate: 'radial',
                  align: "right",
                  color: '#fff',
                  // width: 90,
                  // overflow: 'truncate',
                }
              }
            ]
          }]
        })

      }
    },
    mounted() {
      // this.getAbilitySunburst();
    },
  }
</script>

<style lang="less" scoped>
  .ability-sunburst {
    #sunburst {
      width: 550px;
      height: 550px;
      margin: 20px auto;
    }

    // @media screen and (min-width: 1250px) {
    //   .myChart-canvas-box {
    //     width: 395px;
    //     height: 348px;
    //   }
    // }

  }
</style>