<template>
    <!-- ability radar  个人能力图谱 雷达图 -->
    <div class="ability-radar">
        <div class="chart-box" v-show="radarFlag">
            <div id="radar"></div>
        </div>
        <!-- 无内容-->
        <Empty :show="!radarFlag"></Empty>
    </div>

</template>

<script>
    import radarJson from "./radar.json";
    export default {
        props: {
            suitId: {
                type: Number
            },
        },
        data() {
            return {
                radarFlag: true,
                radarIndicator: [],
                radarDataValue: [],
                radarJunValue: [],
                radarJulValue: [],
                radarDecValue: [],
                keyList: [],
            }
        },
        // watch: {
        //     suitId: {
        //         handler(newVal, oldVal) {
        //             // if (!newVal) return;
        //             this.getAbilityRadar();
        //         },
        //         immediate: true,
        //     }
        // },
        methods: {
            // 获取综合个人能力数据数据
            getAbilityRadar() {
                let resData = radarJson;
                console.log('雷达图数据', resData)
                this.radarIndicator = [];
                this.radarDataValue = [];
                this.keyList = [];
                for (const key in resData) {
                    this.keyList.push(key)
                    if (key == '2023年1月') {
                        resData[key].map(item => {
                            let data = {
                                name: item.name,
                                max: 80
                            }
                            this.radarJunValue.push(item.value);
                            this.radarIndicator.push(data);
                        })
                    }

                    if (key == '2023年7月') {
                        this.radarJulValue.push()
                        resData[key].map(item => {
                            this.radarJulValue.push(item.value);
                        })
                    }

                    if (key == '2023年12月') {
                        this.radarJulValue.push()
                        resData[key].map(item => {
                            this.radarDecValue.push(item.value);
                        })
                    }




                    // if (Array.prototype.isPrototypeOf(resData[key]) && resData[key].length > 0) {
                    //     this.radarFlag = true;
                    //     // const maxData = Math.max.apply(Math, resData[key].map(function (item) {
                    //     //     return item.value << 0
                    //     // }));
                    //     let valueList = [];
                    //     let indicatorList = [];
                    //     resData[key].map(item => {
                    //         let data = {
                    //             name: item.name,
                    //             // max: maxData + 5,
                    //             max: 80
                    //         }
                    //         valueList.push(item.value);
                    //         indicatorList.push(data);
                    //     })
                    //     this.radarDataValue.push(valueList);
                    //     this.radarIndicator.push(indicatorList);

                    //     this.drawRadar();
                    // } else {
                    //     this.radarFlag = false;
                    // }
                }

                this.drawRadar();


            },
            drawRadar() {
                let myChartRadar = this.$echarts.init(
                    document.getElementById("radar")
                );
                window.addEventListener('resize', () => {
                    myChartRadar.resize()
                })
                let that = this;
                myChartRadar.setOption({
                    legend: {
                        type: "plain",
                        bottom: "0%",
                        icon: 'circle',
                        // data: [{
                        //         name: this.keyList[0],
                        //         itemStyle: {
                        //             color: "rgba(102,102,102,1)"
                        //         }
                        //     },
                        //     {
                        //         name: this.keyList[1],
                        //         itemStyle: {
                        //             color: "rgba(21,42,209,1)"
                        //         }
                        //     },
                        //     {
                        //         name: this.keyList[2],
                        //         itemStyle: {
                        //             color: "rgba(168,223,250,1)"
                        //         }
                        //     }
                        // ]
                    },
                    tooltip: {
                        // trigger: 'item',
                    },
                    radar: [{
                        indicator: this.radarIndicator,
                        // radius: '80%',
                        // center: ['50%', '50%'],
                        splitNumber: 4,
                        shape: "circle",
                        name: {
                            show: true,
                            color: "#123456",
                            fontSize: "15",

                            // formatter: "{value}",
                            // formatter: function (text) {
                            //     var strlength = text.length;
                            //     if (strlength % 4 == 2) {
                            //         text = text.replace(/\S{6}/g, function (match) {
                            //             // console.log(match);
                            //             return match + '\n'
                            //         })
                            //     } else {
                            //         text = text.replace(/\S{6}/g, function (match) {
                            //             // console.log(match);
                            //             return match + '\n'
                            //         })
                            //         strlength = text.length;
                            //         text = text.substring(0, strlength);
                            //     }
                            //     return text
                            // },

                        },
                        splitArea: {
                            areaStyle: {
                                color: [
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",
                                    "rgba(255, 255,255,1)",

                                ],
                                shadowColor: "rgba(0, 0, 0, 0)",
                                // shadowBlur: 10
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#E1F1FE"
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: "#E5F3FE"
                            }
                        },
                        // axisName: {
                        //     show: false,
                        //     // formatter: '{b}',
                        //     // position: 'outer',
                        //     // rich: {
                        //     //     '{b}': {
                        //     //         color: '#fff', // 设置弧形文字颜色
                        //     //         fontSize: 14, // 设置弧形文字字体大小
                        //     //         backgroundColor: '#fff', // 设置弧形文字背景颜色
                        //     //         borderColor: '#333', // 设置弧形文字边框颜色
                        //     //         borderWidth: 1, // 设置弧形文字边框宽度
                        //     //         padding: [5, 10], // 设置弧形文字内边距
                        //     //         borderRadius: 4, // 设置弧形文字边框圆角
                        //     //         width: 20, // 设置弧形文字宽度
                        //     //         height: 20, // 设置弧形文字高度
                        //     //         lineHeight: 20, // 设置弧形文字行高
                        //     //         textAlign: 'center', // 设置弧形文字水平对齐方式
                        //     //         verticalAlign: 'middle', // 设置弧形文字垂直对齐方式
                        //     //     }
                        //     // }
                        // }
                    }],
                    series: [{
                        name: "个人能力图谱",
                        type: "radar",
                        emphasis: {},
                        // data: [{
                        //         value: this.radarJunValue,
                        //         name: '2023年1月'
                        //     }, {
                        //         value: this.radarJulValue,
                        //         name: '2023年7月'
                        //     },
                        //     {
                        //         value: this.radarDecValue,
                        //         name: '2023年12月'
                        //     }
                        // ]
                        data: [{
                                name: this.keyList[0],
                                value: this.radarJunValue,
                                symbol: "none", //设置拐点格式样式
                                symbolSize: 5,
                                itemStyle: {
                                    color: "rgba(102,102,102,1)",
                                    borderColor: "#fff",
                                    borderWidth: 1
                                },
                                lineStyle: {
                                    color: "rgba(102,102,102,1)"
                                },
                                areaStyle: {
                                    color: "rgba(102,102,102,0.8)"
                                },

                            },
                            {
                                name: this.keyList[1],
                                value: this.radarJulValue,
                                symbol: "none", //设置拐点格式样式
                                symbolSize: 5,
                                itemStyle: {
                                    color: "rgba(21,42,209,1)",
                                    borderColor: "#fff",
                                    borderWidth: 1
                                },
                                lineStyle: {
                                    color: "rgba(21,42,209,1)"
                                },
                                areaStyle: {
                                    color: "rgba(21,42,209,0.8)"
                                },

                            }, {
                                name: this.keyList[2],
                                value: this.radarDecValue,
                                symbol: "none", //设置拐点格式样式
                                symbolSize: 5,
                                itemStyle: {
                                    color: "rgba(168,223,250,1)",
                                    borderColor: "#fff",
                                    borderWidth: 1
                                },
                                lineStyle: {
                                    color: "rgba(168,223,250,1)"
                                },
                                areaStyle: {
                                    color: "rgba(168,223,250,0.8)"
                                    // color: {
                                    //     type: 'linear',
                                    //     x: 0,
                                    //     y: 0,
                                    //     x2: 0,
                                    //     y2: 1,
                                    //     colorStops: [{
                                    //         offset: 0,
                                    //         color: '#15A1E2' // 0% 处的颜色
                                    //     }, {
                                    //         offset: 1,
                                    //         color: '#126CC0' // 100% 处的颜色
                                    //     }],
                                    // }
                                },

                            },
                        ]
                    }]
                })

            }
        },
        mounted() {
            this.getAbilityRadar();
        },
    }
</script>

<style lang="less" scoped>
    .ability-radar {
        #radar {
            width: 700px;
            height: 650px;
            margin: 20px auto;
            margin-bottom: 100px;
        }

        // @media screen and (min-width: 1250px) {
        //   .myChart-canvas-box {
        //     width: 395px;
        //     height: 348px;
        //   }
        // }

    }
</style>